<template>
  <section>
    <div class="richtext">
      <p>
        Ark Electric and Mechanical Company Limited, known as manufacturing
        NEWDOSE dosing pumps, produce the first solenoid driven dosing pump in
        China market. Our company mission is that daring to be the first, fight
        our way upward, working hard, innovating pragmatically and environment
        harmonious. During to the past twenty years’ experience, our business
        goal is being the model in our dosing pump industry and being famous in
        the world brand.
      </p>
      <p><br /></p>
      <p>
        We know it's a long way to go and to make more contributions to help the
        water treatment field. Our corporate philosophy is that concept guides
        the ideas, ideas promote innovation, quality casts brand, deficiency
        improve speed, science creates perfect. So we continuously explore new
        types of dosing pumps to meet the ending users’ requirements. It's a
        basic rule to work out people’s concerns about water and solve the
        problems from the equipment and increase its efficiency. It will come
        true that we can well deal with the water problems and more clean water
        around us.
      </p>
      <p><br /></p>
      <p>
        Newdose products are being improved step by step all the way and
        meanwhile quality is our top priority. Firstly, it's the solenoid pump,
        then several water meters, and comes the motor-driven dosing pump, even
        kinds of the control system. The business philosophy is that persistent
        to work hard, promotion of marketing by open, creation an environment,
        an extension of the market with our service. Every employee is got
        involved, appreciating the teamwork, taking special emphasis, creation
        in science, access to the market with excellent quality.
      </p>
      <p><br /></p>
      <p>
        The market concept is to build a partnership environment on the basic
        win-win benefits, getting production and marketing together because of
        our common fate. We'll offer the best service to the customer. We take
        customer satisfaction to be our eternal pursuit.
      </p>
      <p><br /></p>
      <p>
        The growth of Newdose products is made by our people, also inspired by
        our customers. We won't stop expanding the market as a platform, take
        product innovation as the pillar and upgrade technology as the bridge,
        treat excellent quality as a guarantee. In the future, we make the
        management to be the first-class modern operation stills.
      </p>
      <p style="text-align: center"></p>
    </div>
  </section>
</template>
<script>
export default {
  title: "Company Culture",
};
</script>